import React from "react";
import {
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
  AssignmentTurnedInTwoTone as ValidIcon,
  WarningTwoTone,
  ReportTwoTone,
  GetAppTwoTone as DownloadIcon,
  EmailTwoTone as RecivedIcon,
  DraftsTwoTone as ReadIcon,
  EmailTwoTone as SentIcon,
  Phone as PhoneIcon,
  CancelScheduleSendTwoTone as UnsentIcon,
  MobileScreenShare,
  Work,
  Event,
  Email,
  Business,
  Feedback,
  PictureAsPdf,
  TouchApp,
  Receipt,
  DoneAll,
  Done,
} from "@material-ui/icons";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Collapse,
  Tooltip,
  TablePagination,
  Box,
  Button,
  ButtonGroup,
  Typography,
  TableContainer,
  Paper,
  LinearProgress,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  ListItem,
  List,
  Avatar,
  FormControlLabel,
  ListItemIcon,
  CircularProgress,
  Backdrop,
  Snackbar,
  Slide,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import {
  createStyles,
  withStyles,
  Theme,
  makeStyles,
} from "@material-ui/core/styles";
import { Colors } from "../../static/colors";
import emptyDocsIllustration from "../../assets/imgs/emptyDocs.png";
import noResultsIllustration from "../../assets/imgs/noResults.png";
import { NoteBox, NoteType } from "../noteBox";
import { VoidDocument } from "../voidDocument";
import { NewCreditNoteModal } from "../newCreditNoteModal";
import { NewDebitNoteModal } from "../newDebitNoteModal";
import { ResendDocumentEmail } from "../resendDocumentEmail";
import { EmailHistory } from "../emailHistory";
import axios from "axios";
import FileDownload from "js-file-download";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { setConfigParam } from "../../../domain/store/actions/config";
import { EmittedDoc, EmailState, Messages } from "../../../domain/store/types/emittedDoc";
import { CompanyInfo } from "../../../domain/store/types/companies";
import {
  getIDDocumentType,
  getCurrencySymbol,
  numberWithCommas,
  getDocumentStatusDescription,
  getDocumentTypeDesc,
  NumeroALetras,
  getUnidadMedida,
  getCreditNoteReason,
  getDebitNoteReason,
  getReceiverFullAddress,
  validateEmail,
  getCurrentDate,
  statusPayment,
} from "../../static/util";
import "./emitted.css";
import Loading from "../../components/skeleton/loading";
import {
  RButton,
  RButtonSecondary,
  RChip,
  RSwitch,
  RTextField,
} from "../ratifikaElements";
import { BASE_URL } from "../../http/Axios";
import logoRatifikaBN from "../../assets/imgs/logoRatifikaBN.png";
import { emissionService } from "src/domain/services/Emission.service";
import { ButtonPrimary, ButtonSecondary } from "../button";
import { InputNumber } from "../input";
import { useForm } from "src/infrastructure/hooks/useForm";
import TextStyle from "../textStyle";
import CustomSelect from "../select";
import countries from "../../assets/data/paises.json";
import { WabaHistory } from "../wabaHistory";
import { useHistory } from "react-router-dom";
interface Column {
  id: "fecha" | "numero" | "tipo" | "receptor" | "total";
  label: string;
  minWidth?: number;
  align?: "right";
  format?: (value: number) => string;
}

interface ToVoidDocument {
  doc_type: string;
  serie: string;
  number: string;
  issue_date: string;
}

export interface EmitterInfo {
  info_extra: any;
  phone_number: any;
  number: number;
  legal_name: string;
  trade_name: string;
  province: string;
  department: string;
  district: string;
  address_line: string;
}

interface Emitter {
  addresses: any;
  legal_name: string;
  number: string;
  number_type: string;
  phone_number: string;
}

interface EmittedDocExtended extends EmittedDoc {
  emitter_company: EmitterInfo;
  emitter: Emitter;
  notes: string;
}

export interface chargePay {
  amount: number;
  currency: string;
  description: string;
  expiration_date: string;
  send_email: boolean;
  redirect_url?: string;
  customer: {
    name: string;
    last_name?: string;
    phone_number?: string;
    email?: string;
  };
}
const columns: Column[] = [
  { id: "fecha", label: "Fecha\u00a0emisión", minWidth: 80 },
  { id: "numero", label: "Número", minWidth: 150 },
  { id: "tipo", label: "Tipo", minWidth: 100 },
  { id: "receptor", label: "Receptor", minWidth: 170 },
  {
    id: "total",
    label: "Total",
    minWidth: 100,
    align: "right",
    format: (value: number) => value.toLocaleString("en-US"),
  },
];
export const generatePrintableVoucher = async (
  docInfo: EmittedDocExtended,
  qr: string
) => {
  //console.log(docInfo)

  let logoVoucher = await axios.get(
    `/api/logo/company/${docInfo.emitter_company?docInfo.emitter_company.number:docInfo.emitter.number}`
  );

  let receiver_full_address =
    docInfo.receiver.addresses !== null
      ? docInfo.receiver.addresses.filter((a: any) => a.default === true)[0]
      : docInfo.receiver.addresses[0];

  let html = `<!DOCTYPE html>
<html>
   <head>
   <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
   <style>
      * {
         font-size: 11px;
         font-family: Arial, Helvetica, sans-serif;
      }

      td,
      th,
      tr,
      table {
         border-top: 1px solid rgb(150, 146, 146);
         border-collapse: collapse;
      }

      .centrado {
         text-align: center;
         align-content: center;
      }

      .ticket {
         width: 250px;
         max-width: 300px;
      }

      img {
         max-width: inherit;
         width: inherit;
      }

      .ratifika {
         max-width: 130px;
         width: 130px;
         margin-left: auto;
         margin-right: auto;
         display: block;
      }
   </style>
   </head>
   <body>
    <div class="ticket" style="text-align: left;">
      <table style="width: 100%;">
        <tbody>
          <tr>
            <td style="width: 100%;">
            <div class="ticket" style="text-align: left;">
                ${
                  logoVoucher.data
                    ? `<img
                    style="display: block; margin-left: auto; margin-right: auto;"
                    src="${logoVoucher.data.logo}"
                    alt="Logotipo" />`
                    : ""
                }
              <p class="centrado" style="text-align: center;">
              ${
                docInfo.emitter_company
                  ? `${docInfo.emitter_company.legal_name.toLocaleUpperCase()}`
                  : `${docInfo.emitter.legal_name.toLocaleUpperCase()}`
              }
              </p>
              <p class="centrado" style="text-align: center;">
                  <strong>RUC:
                    ${
                      docInfo.emitter_company
                        ? docInfo.emitter_company.number
                        : docInfo.emitter.number
                    }
                  </strong>
                  <br />
                  Direci&oacute;n: ${
                    docInfo.emitter_company
                      ? `${docInfo.emitter_company.address_line}`
                      : `${docInfo.emitter.addresses[0].address_line}`
                  }
                  <br />
                  ${
                    docInfo.emitter_company
                      ? `${docInfo.emitter_company.province},&nbsp;${docInfo.emitter_company.department}&nbsp;-&nbsp;${docInfo.emitter_company.district}&nbsp;<br />TELF:&nbsp;${docInfo.emitter_company.phone_number}`
                      : ""
                  }
              </p>
              <h1 style="text-align: center;"><strong>
                  ${getDocumentTypeDesc(
                    docInfo.document_type
                  ).toLocaleUpperCase()}
                  ELECTR&Oacute;NICA <br />${docInfo.serie}-${
    docInfo.number
  } </strong>
              </h1>
              <p class="centrado" style="text-align: left;">
                <strong>RUC: 
                  ${docInfo.receiver ? docInfo.receiver.number : ""}
                </strong>
                        <br /><strong>
                        ${docInfo.receiver ? docInfo.receiver.legal_name : ""}
                        </strong>
                        <br />Direccion:  
                            ${`${receiver_full_address.address_line},&nbsp;<br />${receiver_full_address.province},${receiver_full_address.district}&nbsp;-&nbsp;${receiver_full_address.department}`}
                        <br />Emisi&oacute;n: ${moment(
                          docInfo.issue_date
                        ).format("DD/MM/YYYY hh:mm A")} 
                        ${
                          docInfo.due_date
                            ? `<br />Vencimiento: ${moment(
                                docInfo.due_date
                              ).format("DD/MM/YYYY")}`
                            : ""
                        }   
                        <br />Moneda : ${
                          docInfo.currency === "PEN" ? "SOLES" : "DOLARES"
                        }</p>
                        ${
                          docInfo.document_type
                            ? docInfo.document_type === "07" ||
                              docInfo.document_type === "08"
                              ? `<p><strong>Documento afectado:</strong> ${
                                  docInfo.note
                                    ? docInfo.note.ref_serie_number
                                    : ""
                                } <br /><strong>Tipo de Nota de
                            Cr&eacute;dito:</strong>  ${
                              docInfo.note
                                ? getCreditNoteReason(
                                    docInfo.note.response_code
                                  )
                                : ""
                            } <br /><strong>Motivo o
                            sustento:</strong> ${
                              docInfo.note ? docInfo.note.description : ""
                            }</p>`
                              : ""
                            : ""
                        }
                     
                            <table style="width: 250px; max-width: 300px;">
                        <thead>
                           <tr>
                              <th style="text-align: center;"><strong>CANT</strong></th>
                              <th style="text-align: center;"><strong>PRODUCTO</strong></th>
                              <th style="text-align: center;"><strong>V. Unit.</strong></th>
                              <th style="text-align: center;"><strong>V. VENTA</strong></th>
                           </tr>
                        </thead>
                        <tbody>
                        ${docInfo.products.map((item) => {
                          return `
                          <tr>
                            <td>${parseInt(`${item.quantity}`).toFixed(
                              3
                            )}&nbsp;${getUnidadMedida(item.measure)}
                            </td>
                            <td style="text-align: left;">${
                              item.description
                            }</td>
                            <td>&nbsp;&nbsp;${parseFloat(
                              `${item.unit_price}`
                            ).toFixed(2)}</td>
                            <td>&nbsp;&nbsp;${
                              item.unit_price > 0 ? item.price : "0.00"
                            }</td>
                          </tr>
                          `;
                        })}
                           
                        </tbody>
                     </table>
                    <p>${NumeroALetras(docInfo.total_value)}</p>
      
                    ${
                      docInfo.notes
                        ? docInfo.notes
                            .split(";?")
                            .map((note) => {
                              return `<p ">
                              <span style="font-size: 11pt; font-family: 'Calibri Light', sans-serif;">&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;${note}</span>
                            </p>`;
                            })
                            .join("")
                        : ""
                    }
                    <br />
                     <table style="width: 250px; max-width: 300px; height: 198px;">
                        <tbody>
                          ${
                            docInfo.base_igv > 0
                              ? `
                              <tr style="height: 18px;">
                              <td style="width: 96.6375px; height: 18px;"><strong>Gravado:</strong></td>
                              <td style="width: 19.6px; height: 18px;">${getCurrencySymbol(
                                docInfo.currency
                              )}</td>
                              <td style="width: 69.95px; height: 18px;">${
                                docInfo.base_igv
                              }</td>
                              <td style="width: 0px; height: 198px;" rowspan="10">&nbsp;</td>
                              <td style="width: 150px; height: 198px;" rowspan="10">
                                <div style="display: flex; justify-content: center; align-items: center;">
                                ${
                                  qr
                                    ? `<img style="display: block; margin-left: auto; margin-right: auto;"
                                src="${qr}"
                                alt="QR" width="120" height="100"/>`
                                    : ""
                                }
                                </div>
                                <p class="centrado" style="text-align: center;">Autorizado mediante resoluci&oacute;n
                                    <br />N&ordm; 034-005-0006805/SUNAT<br />Consulte este documento en:</p>
                              </td>
                              </tr>
                            `
                              : `
                            <tr style="height: 18px;">
                            <td style="width: 96.6375px; height: 18px;"></td>
                            <td style="width: 19.6px; height: 18px;"></td>
                            <td style="width: 69.95px; height: 18px;"></td>
                              <td style="width: 0px; height: 198px;" rowspan="10">&nbsp;</td>
                              <td style="width: 150px; height: 198px;" rowspan="10">
                                <div style="display: flex; justify-content: center; align-items: center;">
                                ${
                                  qr
                                    ? `<img style="display: block; margin-left: auto; margin-right: auto;"
                                src="${qr}"
                                alt="QR" width="120" height="100"/>`
                                    : ""
                                }
                                </div>
                                <p class="centrado" style="text-align: center;">Autorizado mediante resoluci&oacute;n
                                    <br />N&ordm; 034-005-0006805/SUNAT<br />Consulte este documento en:</p>
                              </td>
                              </tr>
                            `
                          }
                           ${
                             docInfo.base_unaffected > 0
                               ? `
                            <tr style="height: 18px;">
                            <td style="width: 96.6375px; height: 18px;"><strong>Inafecto:</strong></td>
                            <td style="width: 19.6px; height: 18px;">${getCurrencySymbol(
                              docInfo.currency
                            )}</td>
                            <td style="width: 69.95px; height: 18px;">${
                              docInfo.base_unaffected
                            }</td>
                            </tr>
                           `
                               : ""
                           }
                           ${
                             docInfo.base_exemption > 0
                               ? `
                            <tr style="height: 18px;">
                            <td style="width: 96.6375px; height: 18px;"><strong>Exonerado:</strong></td>
                            <td style="width: 19.6px; height: 18px;">${getCurrencySymbol(
                              docInfo.currency
                            )}</td>
                            <td style="width: 69.95px; height: 18px;">${
                              docInfo.base_exemption
                            }</td>
                            </tr>
                           `
                               : ""
                           }
                           
                           <tr style="height: 18px;">
                              <td style="width: 96.6375px; height: 18px;"><strong>Subtotal:</strong></td>
                              <td style="width: 19.6px; height: 18px;">${getCurrencySymbol(
                                docInfo.currency
                              )} </td>
                              <td style="width: 69.95px; height: 18px;">${
                                docInfo.subtotal
                              }</td>
                           </tr>
                           <tr style="height: 18px;">
                              <td style="width: 96.6375px; height: 18px;"><strong>IGV:</strong></td>
                              <td style="width: 19.6px; height: 18px;">${getCurrencySymbol(
                                docInfo.currency
                              )} </td>
                              <td style="width: 69.95px; height: 18px;">${
                                docInfo.total_igv
                              }</td>
                           </tr>
                           ${
                             docInfo.total_isc > 0
                               ? `
                              <tr style="height: 18px;">
                              <td style="width: 96.6375px; height: 18px;"><strong>ISC:</strong></td>
                              <td style="width: 19.6px; height: 18px;">${getCurrencySymbol(
                                docInfo.currency
                              )} </td>
                              <td style="width: 69.95px; height: 18px;">${
                                docInfo.total_isc
                              }</td>
                              </tr>
                            `
                               : ""
                           }
                           ${
                             docInfo.other_charges > 0
                               ? `
                            <tr style="height: 36px;">
                            <td style="width: 96.6375px; height: 36px;"><strong>Otros Cargos:</strong></td>
                            <td style="width: 19.6px; height: 36px;">${getCurrencySymbol(
                              docInfo.currency
                            )}</td>
                            <td style="width: 69.95px; height: 36px;">${
                              docInfo.other_charges
                            }</td>
                            </tr>
                           `
                               : ""
                           }
                           ${
                             docInfo.total_discount > 0
                               ? `
                            <tr style="height: 18px;">
                            <td style="width: 96.6375px; height: 18px;"><strong>Descuentos:</strong></td>
                            <td style="width: 19.6px; height: 18px;">${getCurrencySymbol(
                              docInfo.currency
                            )}</td>
                            <td style="width: 69.95px; height: 18px;">-${
                              docInfo.total_discount
                            }</td>
                            </tr>   
                           `
                               : ""
                           }
                           ${
                             docInfo.prepaid_amount > 0
                               ? `
                            <tr style="height: 18px;">
                            <td style="width: 96.6375px; height: 18px;"><strong>Anticipo:</strong></td>
                            <td style="width: 19.6px; height: 18px;">${getCurrencySymbol(
                              docInfo.currency
                            )}</td>
                            <td style="width: 69.95px; height: 18px;">-${
                              docInfo.prepaid_amount
                            }</td>
                            </tr>   
                           `
                               : ""
                           }
                           
                           <tr style="height: 18px;">
                              <td style="width: 96.6375px; height: 18px;"><strong>Total:</strong></td>
                              <td style="width: 19.6px; height: 18px;"><strong>${getCurrencySymbol(
                                docInfo.currency
                              )}</strong></td>
                              <td style="width: 69.95px; height: 18px;"><strong>${
                                docInfo.total_value
                              }</strong></td>
                           </tr>
                        </tbody>
                     </table>

                     <img class="ratifika"
                        src="${logoRatifikaBN}" alt="RATIFIKA"
                        width="50px" />
                     <h1 style="text-align: center;">RATIFIKA.COM.PE<br />innovaci&oacute;n y tecnolog&iacute;a de
                        Eximio</h1>  
            </div> 
            </tr>
         </tbody>
      </table>
   </div>
</body>
  </html>`;

  return html;
};
/* export const generatePrintableVoucher = (docInfo: EmittedDocExtended, qr: string) => {
  
  let html = `<!DOCTYPE html>
  <html
    xmlns:v="urn:schemas-microsoft-com:vml"
    xmlns:o="urn:schemas-microsoft-com:office:office"
    xmlns:w="urn:schemas-microsoft-com:office:word"
    xmlns:m="http://schemas.microsoft.com/office/2004/12/omml"
    xmlns="http://www.w3.org/TR/REC-html40"
  >
    <head>
      <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
      <style>
        <!--
         @font-face
          {font-family:"Cambria Math";
          panose-1:2 4 5 3 5 4 6 3 2 4;
          mso-font-charset:0;
          mso-generic-font-family:roman;
          mso-font-pitch:variable;
          mso-font-signature:3 0 0 0 1 0;}
        @font-face
          {font-family:Calibri;
          panose-1:2 15 5 2 2 2 4 3 2 4;
          mso-font-charset:0;
          mso-generic-font-family:swiss;
          mso-font-pitch:variable;
          mso-font-signature:-1610611985 1073750139 0 0 159 0;}
        @font-face
          {font-family:"Calibri Light";
          panose-1:2 15 3 2 2 2 4 3 2 4;
          mso-font-charset:0;
          mso-generic-font-family:swiss;
          mso-font-pitch:variable;
          mso-font-signature:-536859905 -1073732485 9 0 511 0;}
         p.MsoNormal, li.MsoNormal, div.MsoNormal
          {mso-style-unhide:no;
          mso-style-qformat:yes;
          mso-style-parent:"";
          margin-top:0cm;
          margin-right:0cm;
          margin-bottom:4.0pt;
          margin-left:0cm;
          mso-pagination:widow-orphan;
          font-size:12.0pt;
          font-family:"Calibri",sans-serif;
          mso-ascii-font-family:Calibri;
          mso-ascii-theme-font:minor-latin;
          mso-fareast-font-family:Calibri;
          mso-fareast-theme-font:minor-latin;
          mso-hansi-font-family:Calibri;
          mso-hansi-theme-font:minor-latin;
          mso-bidi-font-family:"Times New Roman";
          mso-bidi-theme-font:minor-bidi;
          mso-fareast-language:EN-US;}
        .MsoChpDefault
          {mso-style-type:export-only;
          mso-default-props:yes;
          font-family:"Calibri",sans-serif;
          mso-ascii-font-family:Calibri;
          mso-ascii-theme-font:minor-latin;
          mso-fareast-font-family:Calibri;
          mso-fareast-theme-font:minor-latin;
          mso-hansi-font-family:Calibri;
          mso-hansi-theme-font:minor-latin;
          mso-bidi-font-family:"Times New Roman";
          mso-bidi-theme-font:minor-bidi;
          mso-fareast-language:EN-US;}
        .MsoPapDefault
          {mso-style-type:export-only;
          margin-bottom:4.0pt;}
        @page WordSection1
          {size:612.0pt 792.0pt;
          margin:70.85pt 3.0cm 70.85pt 3.0cm;
          mso-header-margin:35.4pt;
          mso-footer-margin:35.4pt;
          mso-paper-source:0;}
        div.WordSection1
          {page:WordSection1;}
        -->
      </style>
      <!--[if gte mso 10]>
        <style>
          table.MsoNormalTable {
            mso-style-name: "Tabla normal";
            mso-tstyle-rowband-size: 0;
            mso-tstyle-colband-size: 0;
            mso-style-noshow: yes;
            mso-style-priority: 99;
            mso-style-parent: "";
            mso-padding-alt: 0cm 5.4pt 0cm 5.4pt;
            mso-para-margin-top: 0cm;
            mso-para-margin-right: 0cm;
            mso-para-margin-bottom: 4pt;
            mso-para-margin-left: 0cm;
            mso-pagination: widow-orphan;
            font-size: 12pt;
            font-family: "Calibri", sans-serif;
            mso-ascii-font-family: Calibri;
            mso-ascii-theme-font: minor-latin;
            mso-hansi-font-family: Calibri;
            mso-hansi-theme-font: minor-latin;
            mso-fareast-language: EN-US;
          }
          table.MsoTableGrid {
            mso-style-name: "Tabla con cuadrícula";
            mso-tstyle-rowband-size: 0;
            mso-tstyle-colband-size: 0;
            mso-style-priority: 39;
            mso-style-unhide: no;
            border: solid windowtext 1pt;
            mso-border-alt: solid windowtext 0.5pt;
            mso-padding-alt: 0cm 5.4pt 0cm 5.4pt;
            mso-border-insideh: 0.5pt solid windowtext;
            mso-border-insidev: 0.5pt solid windowtext;
            mso-para-margin: 0cm;
            mso-pagination: widow-orphan;
            font-size: 12pt;
            font-family: "Calibri", sans-serif;
            mso-ascii-font-family: Calibri;
            mso-ascii-theme-font: minor-latin;
            mso-hansi-font-family: Calibri;
            mso-hansi-theme-font: minor-latin;
            mso-fareast-language: EN-US;
          }
        </style>
      <![endif]-->
    </head>
  
    <body lang="ES-PE" style="tab-interval: 35.4pt; word-wrap: break-word">
      <div class="WordSection1">
        <table
          class="MsoTableGrid"
          border="0"
          cellspacing="0"
          cellpadding="0"
          style="
            border-collapse: collapse;
            border: none;
            mso-yfti-tbllook: 1184;
            mso-padding-alt: 0cm 5.4pt 0cm 5.4pt;
            mso-border-insideh: none;
            mso-border-insidev: none;
          "
        >
          <tr style="mso-yfti-irow: 0; mso-yfti-firstrow: yes">
            <td width="321" valign="top" style="width: 241pt; padding: 0cm 5.4pt 0cm 5.4pt">
              <p class="MsoNormal" style="margin-bottom: 0cm">
                <span
                  style="
                    font-family: 'Calibri Light', sans-serif;
                    mso-ascii-theme-font: major-latin;
                    mso-hansi-theme-font: major-latin;
                    mso-bidi-theme-font: major-latin;
                    mso-no-proof: yes;
                  "
                  > <!-- <img
                    width="210"
                    height="70"
                    src="VOUCHER.fld/image001.png"
                    v:shapes="Imagen_x0020_5"
                  /> --> </span
                ><span
                  style="
                    font-family: 'Calibri Light', sans-serif;
                    mso-ascii-theme-font: major-latin;
                    mso-hansi-theme-font: major-latin;
                    mso-bidi-theme-font: major-latin;
                  "
                  ><o:p></o:p
                ></span>
              </p>
              <p class="MsoNormal" style="margin-bottom: 0cm">
                <span
                  style="
                    font-family: 'Calibri Light', sans-serif;
                    mso-ascii-theme-font: major-latin;
                    mso-hansi-theme-font: major-latin;
                    mso-bidi-theme-font: major-latin;
                  "
                  ><o:p>&nbsp;</o:p></span
                >
              </p>
              <p class="MsoNormal" style="margin-bottom: 0cm">
                <span
                  style="
                    font-family: 'Calibri Light', sans-serif;
                    mso-ascii-theme-font: major-latin;
                    mso-hansi-theme-font: major-latin;
                    mso-bidi-theme-font: major-latin;
                  "
                  >${docInfo.emitter_company ? docInfo.emitter_company.legal_name : docInfo.emitter.legal_name}<o:p></o:p
                ></span>
              </p>
            </td>
            <td width="267" valign="top" style="width: 200.5pt; padding: 0cm 5.4pt 0cm 5.4pt">
              <p class="MsoNormal" align="center" style="margin-bottom: 0cm; text-align: center">
                <b
                  ><span
                    style="
                      font-family: 'Calibri Light', sans-serif;
                      mso-ascii-theme-font: major-latin;
                      mso-hansi-theme-font: major-latin;
                      mso-bidi-theme-font: major-latin;
                    "
                    >${getDocumentTypeDesc(
    docInfo.document_type
  ).toLocaleUpperCase()}<o:p></o:p></span
                ></b>
              </p>
              <p class="MsoNormal" align="center" style="margin-bottom: 0cm; text-align: center">
                <b
                  ><span
                    style="
                      font-family: 'Calibri Light', sans-serif;
                      mso-ascii-theme-font: major-latin;
                      mso-hansi-theme-font: major-latin;
                      mso-bidi-theme-font: major-latin;
                    "
                    >ELECTRÓNICA<o:p></o:p></span
                ></b>
              </p>
              <p class="MsoNormal" align="center" style="margin-bottom: 0cm; text-align: center">
                <b
                  ><span
                    style="
                      font-family: 'Calibri Light', sans-serif;
                      mso-ascii-theme-font: major-latin;
                      mso-hansi-theme-font: major-latin;
                      mso-bidi-theme-font: major-latin;
                    "
                    >R.U.C.:</span
                  ></b
                ><span
                  style="
                    font-family: 'Calibri Light', sans-serif;
                    mso-ascii-theme-font: major-latin;
                    mso-hansi-theme-font: major-latin;
                    mso-bidi-theme-font: major-latin;
                  "
                >
                ${docInfo.emitter_company ? docInfo.emitter_company.number : docInfo.emitter.number}<o:p></o:p
                ></span>
              </p>
              <p class="MsoNormal" align="center" style="margin-bottom: 0cm; text-align: center">
                <b
                  ><span
                    style="
                      font-family: 'Calibri Light', sans-serif;
                      mso-ascii-theme-font: major-latin;
                      mso-hansi-theme-font: major-latin;
                      mso-bidi-theme-font: major-latin;
                    "
                    ><o:p>&nbsp;</o:p></span
                  ></b
                >
              </p>
              <p class="MsoNormal" align="center" style="margin-bottom: 0cm; text-align: center">
                <b
                  ><span
                    style="
                      font-family: 'Calibri Light', sans-serif;
                      mso-ascii-theme-font: major-latin;
                      mso-hansi-theme-font: major-latin;
                      mso-bidi-theme-font: major-latin;
                    "
                    >Nº:</span
                  ></b
                ><span
                  style="
                    font-family: 'Calibri Light', sans-serif;
                    mso-ascii-theme-font: major-latin;
                    mso-hansi-theme-font: major-latin;
                    mso-bidi-theme-font: major-latin;
                  "
                >
                  ${docInfo.serie}-${docInfo.number}<o:p></o:p
                ></span>
              </p>
            </td>
          </tr>
          <tr style="mso-yfti-irow: 1">
            <td
              width="589"
              colspan="2"
              valign="top"
              style="width: 441.5pt; padding: 0cm 5.4pt 0cm 5.4pt"
            >
            <p></p>
              <p class="MsoNormal" style="margin-bottom: 0cm">
                <span
                  style="
                    font-family: 'Calibri Light', sans-serif;
                    mso-ascii-theme-font: major-latin;
                    mso-hansi-theme-font: major-latin;
                    mso-bidi-theme-font: major-latin;
                  "
                  >DIRECCIÓN FISCAL: ${docInfo.emitter_company ? `

                    ${docInfo.emitter_company.address_line.toLocaleUpperCase()} - ${docInfo.emitter_company.department.toLocaleUpperCase()} - ${docInfo.emitter_company.province.toLocaleUpperCase()} - ${docInfo.emitter_company.district.toLocaleUpperCase()}` : ""
    }
                  
                  <o:p></o:p
                ></span>
              </p>
            </td>
          </tr>
          <tr style="mso-yfti-irow: 2; mso-yfti-lastrow: yes">
            <td
              width="589"
              colspan="2"
              valign="top"
              style="width: 441.5pt; padding: 0cm 5.4pt 0cm 5.4pt"
            >
            <!-- <p class="MsoNormal" style="margin-bottom: 0cm">
                <span
                  style="
                    font-family: 'Calibri Light', sans-serif;
                    mso-ascii-theme-font: major-latin;
                    mso-hansi-theme-font: major-latin;
                    mso-bidi-theme-font: major-latin;
                  "
                  >Dirección Sucursal: Av La molina 9904 Of 40022 La Molina<o:p></o:p
                ></span>
              </p> -->
            </td>
          </tr>
        </table>
  
        <p class="MsoNormal"><o:p>&nbsp;</o:p></p>
  
        <table
          class="MsoTableGrid"
          border="0"
          cellspacing="0"
          cellpadding="0"
          style="
            border-collapse: collapse;
            border: none;
            mso-yfti-tbllook: 1184;
            mso-padding-alt: 0cm 5.4pt 0cm 5.4pt;
            mso-border-insideh: none;
            mso-border-insidev: none;
          "
        >
          <tr style="mso-yfti-irow: 0; mso-yfti-firstrow: yes">
            <td width="340" valign="top" style="width: 254.65pt; padding: 0cm 5.4pt 0cm 5.4pt">
              <p class="MsoNormal" style="margin-bottom: 0cm">
                <span
                  style="
                    font-family: 'Calibri Light', sans-serif;
                    mso-ascii-theme-font: major-latin;
                    mso-hansi-theme-font: major-latin;
                    mso-bidi-theme-font: major-latin;
                  "
                  >CLIENTE: <span style="mso-spacerun: yes">     </span>${docInfo.receiver.legal_name
    }<o:p></o:p
                ></span>
              </p>
            </td>
            <td width="28" valign="top" style="width: 21.3pt; padding: 0cm 5.4pt 0cm 5.4pt">
              <p class="MsoNormal" style="margin-bottom: 0cm">
                <span
                  style="
                    font-family: 'Calibri Light', sans-serif;
                    mso-ascii-theme-font: major-latin;
                    mso-hansi-theme-font: major-latin;
                    mso-bidi-theme-font: major-latin;
                  "
                  ><o:p>&nbsp;</o:p></span
                >
              </p>
            </td>
            <td width="221" valign="top" style="width: 165.45pt; padding: 0cm 5.4pt 0cm 5.4pt">
              <p class="MsoNormal" style="margin-bottom: 0cm">
                <span
                  style="
                    font-family: 'Calibri Light', sans-serif;
                    mso-ascii-theme-font: major-latin;
                    mso-hansi-theme-font: major-latin;
                    mso-bidi-theme-font: major-latin;
                  "
                  >FECHA EMISIÓN: ${moment(docInfo.issue_date).format(
      "DD/MM/YYYY hh:mm A"
    )}<o:p></o:p
                ></span>
              </p>
            </td>
          </tr>
          <tr style="mso-yfti-irow: 1">
            <td width="340" valign="top" style="width: 254.65pt; padding: 0cm 5.4pt 0cm 5.4pt">
              <p class="MsoNormal" style="margin-bottom: 0cm">
                <span
                  style="
                    font-family: 'Calibri Light', sans-serif;
                    mso-ascii-theme-font: major-latin;
                    mso-hansi-theme-font: major-latin;
                    mso-bidi-theme-font: major-latin;
                  "
                  >${docInfo.receiver.number_type === "6" ? "RUC" : "IDENTIFICACIÓN"
    }: <span style="mso-spacerun: yes"></span>${docInfo.receiver.number}<o:p></o:p
                ></span>
              </p>
            </td>
            <td width="28" valign="top" style="width: 21.3pt; padding: 0cm 5.4pt 0cm 5.4pt">
              <p class="MsoNormal" style="margin-bottom: 0cm">
                <span
                  style="
                    font-family: 'Calibri Light', sans-serif;
                    mso-ascii-theme-font: major-latin;
                    mso-hansi-theme-font: major-latin;
                    mso-bidi-theme-font: major-latin;
                  "
                  ><o:p>&nbsp;</o:p></span
                >
              </p>
            </td>
            <td width="221" valign="top" style="width: 165.45pt; padding: 0cm 5.4pt 0cm 5.4pt">
            <!--<p class="MsoNormal" style="margin-bottom: 0cm">
                <span
                  style="
                    font-family: 'Calibri Light', sans-serif;
                    mso-ascii-theme-font: major-latin;
                    mso-hansi-theme-font: major-latin;
                    mso-bidi-theme-font: major-latin;
                  "
                  >FECHA DE VENC: 25/02/2021<o:p></o:p
                ></span>
              </p>-->
            </td>
          </tr>
          <tr style="mso-yfti-irow: 2; mso-yfti-lastrow: yes">
            <td width="340" valign="top" style="width: 254.65pt; padding: 0cm 5.4pt 0cm 5.4pt">
              <p class="MsoNormal" style="margin-bottom: 0cm">
                <span
                  style="
                    font-family: 'Calibri Light', sans-serif;
                    mso-ascii-theme-font: major-latin;
                    mso-hansi-theme-font: major-latin;
                    mso-bidi-theme-font: major-latin;
                  "
                  >Dirección: <span style="mso-spacerun: yes">  </span>${docInfo.receiver_address ? docInfo.receiver_address.address_line : ''
    }<o:p></o:p
                ></span>
              </p>
              <!-- <p class="MsoNormal" style="margin-bottom: 0cm">
                <span
                  style="
                    font-family: 'Calibri Light', sans-serif;
                    mso-ascii-theme-font: major-latin;
                    mso-hansi-theme-font: major-latin;
                    mso-bidi-theme-font: major-latin;
                  "
                  ><span style="mso-spacerun: yes">                     </span>PINAR LIMA - LIMA -
                  COMAS<o:p></o:p
                ></span>
              </p> -->
            </td>
            <td width="28" valign="top" style="width: 21.3pt; padding: 0cm 5.4pt 0cm 5.4pt">
              <p class="MsoNormal" style="margin-bottom: 0cm">
                <span
                  style="
                    font-family: 'Calibri Light', sans-serif;
                    mso-ascii-theme-font: major-latin;
                    mso-hansi-theme-font: major-latin;
                    mso-bidi-theme-font: major-latin;
                  "
                  ><o:p>&nbsp;</o:p></span
                >
              </p>
            </td>
            <td width="221" valign="top" style="width: 165.45pt; padding: 0cm 5.4pt 0cm 5.4pt">
            <!-- <p class="MsoNormal" style="margin-bottom: 0cm">
                <span
                  style="
                    font-family: 'Calibri Light', sans-serif;
                    mso-ascii-theme-font: major-latin;
                    mso-hansi-theme-font: major-latin;
                    mso-bidi-theme-font: major-latin;
                  "
                  >PAGO: <span style="mso-spacerun: yes">                  </span
                  ><span style="mso-spacerun: yes"> </span>CONTADO<o:p></o:p
                ></span>
              </p> -->
            </td>
          </tr>
        </table>
  
        <p class="MsoNormal"><o:p>&nbsp;</o:p></p>
  
        <table
          class="MsoTableGrid"
          border="0"
          cellspacing="0"
          cellpadding="0"
          style="
            border-collapse: collapse;
            border: none;
            mso-yfti-tbllook: 1184;
            mso-padding-alt: 0cm 5.4pt 0cm 5.4pt;
            mso-border-insideh: none;
            mso-border-insidev: none;
          "
        >
          <tr style="mso-yfti-irow: 0; mso-yfti-firstrow: yes">
            <td
              width="66"
              valign="top"
              style="
                width: 49.15pt;
                border-top: double windowtext 1.5pt;
                border-left: none;
                border-bottom: double windowtext 1.5pt;
                border-right: none;
                padding: 0cm 5.4pt 0cm 5.4pt;
              "
            >
              <p class="MsoNormal" style="margin-bottom: 0cm">CANT.</p>
            </td>
            <td
              width="416"
              valign="top"
              style="
                width: 11cm;
                border-top: double windowtext 1.5pt;
                border-left: none;
                border-bottom: double windowtext 1.5pt;
                border-right: none;
                padding: 0cm 5.4pt 0cm 5.4pt;
              "
            >
              <p class="MsoNormal" style="margin-bottom: 0cm">DESCRIPCIÓN</p>
            </td>
            <td
              width="85"
              valign="top"
              style="
                width: 63.75pt;
                border-top: double windowtext 1.5pt;
                border-left: none;
                border-bottom: double windowtext 1.5pt;
                border-right: none;
                padding: 0cm 5.4pt 0cm 5.4pt;
              "
            >
              <p class="MsoNormal" style="margin-bottom: 0cm">TOTAL</p>
            </td>
          </tr>`;

  html += docInfo.products
    .map((item) => {
      return `<tr style="mso-yfti-irow: 1">
            <td
              width="66"
              valign="top"
              style="
                width: 49.15pt;
                border: none;
                mso-border-top-alt: double windowtext 1.5pt;
                padding: 0cm 5.4pt 0cm 5.4pt;
              "
            >
              <p class="MsoNormal" style="margin-bottom: 0cm">${item.quantity}${getUnidadMedida(
        item.measure
      )}</p>
            </td>
            <td
              width="416"
              valign="top"
              style="
                width: 11cm;
                border: none;
                mso-border-top-alt: double windowtext 1.5pt;
                padding: 0cm 5.4pt 0cm 5.4pt;
              "
            >
              <p class="MsoNormal" style="margin-bottom: 0cm">${item.description}</p>
            </td>
            <td
              width="85"
              valign="top"
              style="
                width: 63.75pt;
                border: none;
                mso-border-top-alt: double windowtext 1.5pt;
                padding: 0cm 5.4pt 0cm 5.4pt;
              "
            >
              <p class="MsoNormal" style="margin-bottom: 0cm">${item.unit_price > 0 ? item.price : "0.00"
        }</p>
            </td>
          </tr>`;
    })
    .join();

  html += `</table>
        <p></p>
        <p class="MsoNormal">
          <span style="color: white; mso-color-alt: windowtext">SON: </span>${NumeroALetras(
    docInfo.total_value
  )}<o:p></o:p>
        </p>
  
        <p class="MsoNormal"><o:p>&nbsp;</o:p></p>
  
        <table
          class="MsoTableGrid"
          border="0"
          cellspacing="0"
          cellpadding="0"
          style="
            margin-left: 124.6pt;
            border-collapse: collapse;
            border: none;
            mso-yfti-tbllook: 1184;
            mso-padding-alt: 0cm 5.4pt 0cm 5.4pt;
            mso-border-insideh: none;
            mso-border-insidev: none;
          "
        >
          <tr style="mso-yfti-irow: 0; mso-yfti-firstrow: yes">
            <td width="268" valign="top" style="width: 200.95pt; padding: 0cm 5.4pt 0cm 5.4pt">
              <p class="MsoNormal" style="margin-bottom: 0cm">SUBTOTAL</p>
            </td>
            <td width="124" valign="top" style="width: 93.3pt; padding: 0cm 5.4pt 0cm 5.4pt">
              <p class="MsoNormal" style="margin-bottom: 0cm">${getCurrencySymbol(
    docInfo.currency
  )} ${docInfo.subtotal}</p>
            </td>
          </tr>
          <tr style="mso-yfti-irow: 1">
            <td width="268" valign="top" style="width: 200.95pt; padding: 0cm 5.4pt 0cm 5.4pt">
              <p class="MsoNormal" style="margin-bottom: 0cm">DCTOS</p>
            </td>
            <td width="124" valign="top" style="width: 93.3pt; padding: 0cm 5.4pt 0cm 5.4pt">
              <p class="MsoNormal" style="margin-bottom: 0cm">${getCurrencySymbol(
    docInfo.currency
  )} ${docInfo.total_discount}</p>
            </td>
          </tr>
          <!-- <tr style="mso-yfti-irow: 2">
            <td width="268" valign="top" style="width: 200.95pt; padding: 0cm 5.4pt 0cm 5.4pt">
              <p class="MsoNormal" style="margin-bottom: 0cm">OP. GRAVADA</p>
            </td>
            <td width="124" valign="top" style="width: 93.3pt; padding: 0cm 5.4pt 0cm 5.4pt">
              <p class="MsoNormal" style="margin-bottom: 0cm">${getCurrencySymbol(
    docInfo.currency
  )} ${docInfo.total_value}</p>
            </td>
          </tr> -->
          <tr style="mso-yfti-irow: 3">
            <td width="268" valign="top" style="width: 200.95pt; padding: 0cm 5.4pt 0cm 5.4pt">
              <p class="MsoNormal" style="margin-bottom: 0cm">I.G.V.</p>
            </td>
            <td width="124" valign="top" style="width: 93.3pt; padding: 0cm 5.4pt 0cm 5.4pt">
              <p class="MsoNormal" style="margin-bottom: 0cm">${getCurrencySymbol(
    docInfo.currency
  )} ${docInfo.total_igv}</p>
            </td>
          </tr>
          <tr style="mso-yfti-irow: 4; mso-yfti-lastrow: yes">
            <td width="268" valign="top" style="width: 200.95pt; padding: 0cm 5.4pt 0cm 5.4pt">
              <p class="MsoNormal" style="margin-bottom: 0cm">TOTAL A PAGAR</p>
            </td>
            <td width="124" valign="top" style="width: 93.3pt; padding: 0cm 5.4pt 0cm 5.4pt">
              <p class="MsoNormal" style="margin-bottom: 0cm">${getCurrencySymbol(
    docInfo.currency
  )} ${docInfo.total_value}</p>
            </td>
          </tr>
        </table>
  
        <p class="MsoNormal"><o:p>&nbsp;</o:p></p>
  
        <p class="MsoNormal" style="margin-bottom: 0cm">
          <span
            style="
              font-size: 11pt;
              font-family: 'Calibri Light', sans-serif;
              mso-ascii-theme-font: major-latin;
              mso-fareast-font-family: 'Times New Roman';
              mso-hansi-theme-font: major-latin;
              mso-bidi-theme-font: major-latin;
              mso-fareast-language: ES-MX;
            "
            ><o:p>&nbsp;</o:p></span
          >
        </p>
  
        <table
          class="MsoTableGrid"
          border="0"
          cellspacing="0"
          cellpadding="0"
          width="567"
          style="
            width: 15cm;
            border-collapse: collapse;
            border: none;
            mso-yfti-tbllook: 1184;
            mso-padding-alt: 0cm 5.4pt 0cm 5.4pt;
            mso-border-insideh: none;
            mso-border-insidev: none;
          "
        >`;

  if (docInfo.notes) {
    html += `<tr>
          <td width="567" valign="bottom" style="width: 15cm; padding: 0cm 5.4pt 0cm 5.4pt">
            <p class="MsoNormal" style="margin-bottom: 0cm">
              <span
                style="
                  font-size: 11pt;
                  font-family: 'Calibri Light', sans-serif;
                  mso-ascii-theme-font: major-latin;
                  mso-fareast-font-family: 'Times New Roman';
                  mso-hansi-theme-font: major-latin;
                  mso-bidi-theme-font: major-latin;
                  mso-fareast-language: ES-MX;
                "
                >Notas:<o:p></o:p
              ></span>
            </p>`;

    html += docInfo.notes
      .split(";?")
      .map((note) => {
        return `<p class="MsoNormal" style="margin-bottom: 0cm">
                <span style="font-size: 11pt; font-family: 'Calibri Light', sans-serif;">${note}</span>
              </p>`;
      })
      .join("");

    html += `<p class="MsoNormal"><o:p>&nbsp;</o:p></p></td></tr>`;
  }

  html += `<tr style="mso-yfti-irow: 0; mso-yfti-firstrow: yes; mso-yfti-lastrow: yes">
            <td width="567" valign="bottom" style="width: 15cm; padding: 0cm 5.4pt 0cm 5.4pt">
              <p class="MsoNormal" style="margin-bottom: 0cm">
                <span
                  style="
                    font-size: 11pt;
                    font-family: 'Calibri Light', sans-serif;
                    mso-ascii-theme-font: major-latin;
                    mso-fareast-font-family: 'Times New Roman';
                    mso-hansi-theme-font: major-latin;
                    mso-bidi-theme-font: major-latin;
                    mso-fareast-language: ES-MX;
                  "
                  >Autorizado mediante resolución <span style="mso-spacerun: yes"> </span>Nº 034-005-0006805/SUNAT <o:p></o:p
                ></span>
              </p>
              <p class="MsoNormal" style="margin-bottom: 0cm">
                <span
                  style="
                    font-size: 11pt;
                    font-family: 'Calibri Light', sans-serif;
                    mso-ascii-theme-font: major-latin;
                    mso-fareast-font-family: 'Times New Roman';
                    mso-hansi-theme-font: major-latin;
                    mso-bidi-theme-font: major-latin;
                    mso-fareast-language: ES-MX;
                  "
                  >Representación impresa de la FACTURA ELECTRÓNICA <o:p></o:p
                ></span>
              </p>  
                    
              <img src=${qr}
              alt="codigo Qr" width="100px"/>
            </td>
          </tr>
        </table>
  
        <!-- <p class="MsoNormal">
          <img
            width="185"
            height="208"
            src="VOUCHER.fld/image002.png"
            align="left"
            hspace="12"
            v:shapes="Imagen_x0020_2"
          /><![endif]><span style="mso-spacerun: yes"> </span
          ><span style="mso-spacerun: yes">     </span>
        </p> -->
  
        <p class="MsoNormal"><o:p>&nbsp;</o:p></p>
      </div>
    </body>
  </html>`;
  return html;
}; */

//const options = ["Crédito", "Débito"];

const EmittedDocItem = (props: {
  doc: EmittedDoc;
  onSendVoided: (toVoid: ToVoidDocument) => void;
  onEmitCreditNote: (toUpdate: EmittedDoc) => void;
  onEmitDebitNote: (toUpdate: EmittedDoc) => void;
  onResentDocumentEmail: (document: EmittedDoc) => void;
  onGetEmailHistory: (emailHistory: EmailState[]) => void;
  onGetWabaHistory: (wabaHistory: Messages[]) => void;
}) => {
  const {
    doc,
    onSendVoided,
    onEmitCreditNote,
    onEmitDebitNote,
    onResentDocumentEmail,
    onGetEmailHistory,
    onGetWabaHistory,
  } = props;
  //console.log("doc0", doc);
  const [open, setOpen] = React.useState(false);
  const [openCharge, setOpenCharge] = React.useState(false);
  const [openWsp, setOpenWsp] = React.useState(false);
  const [concepto, setConcepto] = React.useState("");
  const [errorConcepto, setErrorConcepto] = React.useState(false);
  const [emissionDate, setEmissionDate] = React.useState<any>(
    moment(doc.issue_date).format("YYYY-MM-DD")
  );
  const [expirationDate, setExpirationDate] = React.useState<any>();
  const [email, setEmail] = React.useState("");
  const [errorEmail, setErrorEmail] = React.useState(false);
  const [errorMsgEmail, setErrorMsgEmail] = React.useState<string | null>(null);
  const [sendEmail, setSendEmail] = React.useState<boolean>(true);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [fileNameWaba, setFileNameWaba] = React.useState<string>("");
  const [copySentWaba, setCopySentWaba] = React.useState<boolean>(false);
  const [loadingSendWaba, setLoadingSendWaba] = React.useState<boolean>(false);
  const [errorSendWaba, setErrorSendWaba] = React.useState<string>("");
  const [hasNewPhoneNumberError, setHasNewPhoneNumberError] = React.useState(false);
  const [errorMsgNewPhoneNumber, setErrorMsgNewPhoneNumber] = React.useState<string | null>(null);
  const {
    form: formWsp,
    onChange: onChangeWsp,
    onChangeSelect,
  } = useForm({
    numberWsp: "",
    code: "+51",
  });

  const config = useSelector((state: any) => state.config);
  const companies = useSelector((state: any) => state.companiesInfo);
  const emitter: CompanyInfo = companies.find(
    (c: CompanyInfo) => c.ruc === config.workspace
  );
  const [showAlert, setShowAlert] = React.useState<boolean>(false);
  const history = useHistory();
  const classes = useStyles();
  /* this is a test to see the doc */

  const downloadXml = async (fileName: string, id: number) => {
    let { data } = await axios.get(
      `/api/emission/download/xml/${fileName}/${id}`,
      {
        responseType: "blob",
      }
    );
    FileDownload(new Blob([data]), `${fileName}.zip`);
  };

  const downloadCDR = async (fileName: string, id: number) => {
    let { data } = await axios.get(
      `/api/emission/download/cdr/${fileName}/${id}`,
      {
        responseType: "blob",
      }
    );
    FileDownload(new Blob([data]), `CDR_${fileName}.zip`);
  };

  const downloadXmlVoid = async (fileNameVoid: string, refId: number) => {
    let { data } = await axios.get(
      `/api/emission/download/void/${fileNameVoid}/${refId}`,
      {
        responseType: "blob",
      }
    );
    FileDownload(new Blob([data]), `${fileNameVoid}.zip`);
  };

  const downloadPdf = async (fileName: string, id: number) => {
    let { data } = await axios.get(
      `/api/emission/download/pdf/${fileName}/${id}`,
      {
        responseType: "blob",
      }
    );
    if (data.size > 700) {
      FileDownload(new Blob([data]), `${fileName}.pdf`);
    }
  };
  const sendPdfWaba = async (fileName: string, id: number) => {
    setLoadingSendWaba(true);
    const senderId = emitter.senders.find((element: any)=>element.state === "1").id;
    
    let { data } = await axios.post(
      `/api/emission/send/waba/${formWsp.code.substr(1)+formWsp.numberWsp}/${fileName}/${id}/${senderId}`,
      
    );
    if(data.result){
      setCopySentWaba(true);
      await emissionService.getEmissionDocuments();
    } else{
      setErrorSendWaba(`Ha ocurrido un error inesperado: ${data.msg}`);
    }
    setLoadingSendWaba(false);
  };
  const printVoucher = async (fileName: string, id: number) => {
    let { data } = await axios.get(
      `/api/emission/download/info/${fileName}/${id}`
    );

    if (data.result) {
      const content: any = await generatePrintableVoucher(data.doc, data.QR);
      let winPrint = window.open(
        "",
        "",
        "left=0,top=0,width=800,height=600,toolbar=0,scrollbars=0,status=0"
      );
      winPrint?.document.write(content);
      winPrint?.document.close();
      setTimeout(() => {
        winPrint?.focus();
        winPrint?.print();
        winPrint?.close();
      }, 1000);
    }
  };

  const canVoidDocument = (doc: EmittedDoc) => {
    if (doc.state !== "1" && doc.state !== "3") {
      return false;
    }
    if (moment().diff(moment(doc.issue_date), "days") + 1 >= 8) {
      return false;
    }
    return true;
  };

  const canEmitNote = (doc: EmittedDoc) => {
    if (doc.document_type !== "01" && doc.document_type !== "03") {
      return false;
    }
    if (doc.state !== "1" && doc.state !== "3") {
      return false;
    }
    return true;
  };

  const canEmitGuia = (doc: EmittedDoc) => {
    if (doc.state !== "1" && doc.state !== "3") {
      return false;
    }
    return true;
  };

  const handleOpenCharge = () => {
    setOpenCharge(true);
  };

  const handleCloseCharge = () => {
    setOpenCharge(false);
  };

  /*primera carga para generar el cobro */
  const operationCharge = () => {
    setSendEmail(true);
    setConcepto(`Cobro por ${emitter.legal_name} - ${doc.serie} ${doc.number}`);
    setEmail(doc.receiver.email_address ? doc.receiver.email_address : "");

    if (doc.payment !== null && doc.due_date !== null) {
      if (statusPayment(doc.payment.status, doc.due_date) === "expirado") {
        setExpirationDate(
          moment(getCurrentDate())
            .add(1, "days")
            .subtract(1, "seconds")
            .format("YYYY-MM-DD HH:mm:ss")
        );
      } else {
        setExpirationDate(moment(doc.due_date).format("YYYY-MM-DD HH:mm:ss"));
      }
    } else {
      setExpirationDate(
        doc.metadata
          ? doc.metadata.formaPago === "Contado"
            ? moment(emissionDate)
                .add(47, "hours")
                .add(59, "minutes")
                .add(59, "seconds")
                .format("YYYY-MM-DD HH:mm:ss")
            : moment(doc.due_date)
                .add(1, "days")
                .subtract(1, "seconds")
                .format("YYYY-MM-DD HH:mm:ss")
          : moment(getCurrentDate())
              .add(1, "days")
              .subtract(1, "seconds")
              .format("YYYY-MM-DD HH:mm:ss")
      );
    }
    return expirationDate;
  };

  /*Genera el cobro de la cuenta */
  const generateCharge = async () => {
    let hasError = false;

    if (sendEmail) {
      if (!validateEmail(email)) {
        setErrorMsgEmail("Ingrese un email válido");
        setErrorEmail(true);
        hasError = true;
      }
    } else {
      setEmail("-@eximio.com.pe");
    }

    if (concepto.trim() === "") {
      setErrorConcepto(true);
      hasError = true;
    }

    let totalAmount;
    if (doc.detraction_detail !== null) {
      totalAmount = (doc.total_value - doc.detraction_detail.monto).toFixed(2);
    } else {
      totalAmount = doc.total_value;
    }
    /*Arma el json request de la peticion */
    var json: chargePay = {
      amount: Number(totalAmount),
      currency: doc.currency,
      description: concepto,
      send_email: sendEmail,
      expiration_date: doc.metadata
        ? doc.metadata.formaPago === "Contado"
          ? moment()
              .add(47, "hours")
              .add(59, "minutes")
              .add(59, "seconds")
              .format("YYYY-MM-DD HH:mm:ss")
          : moment().add(30, "days").format("YYYY-MM-DD HH:mm:ss")
        : moment().add(30, "days").format("YYYY-MM-DD HH:mm:ss"),
      redirect_url: BASE_URL,
      customer: {
        name: doc.receiver.legal_name,
        email: sendEmail ? email.trim() : "-@eximio.com.pe",
      },
    };

    if (!hasError) {
      setLoading(true);

      let { data } = await axios.post(
        `/api/charge/invoice/${doc.file_name}`,
        json
      );
      if (data.result) {
        setLoading(false);
        setOpenCharge(false);
        // await fetch(["emission"]);
        await emissionService.getEmissionDocuments();
      }
    }
  };

  /*Valida si tiene registrado el tipo de modena en las credenciales de OPENPAY */
  const hasCredentialsOpenPay = () => {
    let company = companies.find(
      (c: CompanyInfo) => c.ruc === config.workspace
    );
    if (!company.credentials_openpay) {
      return false;
    }
    if (company.credentials_openpay[doc.currency]["merchantId"] === "") {
      return false;
    } else {
      return true;
    }
  };
  /*reenvio de notificacion por correo */
  const resendCharge = async () => {
    let hasError = false;
    if (!validateEmail(email) || email.trim() === "") {
      setErrorMsgEmail("Ingrese un email válido");
      setErrorEmail(true);
      hasError = true;
    }

    if (!hasError) {
      setLoading(true);
      setErrorMsgEmail(null);

      const metadaEmail = {
        filename: doc.file_name,
        documentId: doc.id,
        emails: email,
        expiration_date: expirationDate,
      };
      let { data } = await axios.post("api/payment/email/advice", metadaEmail);
      if (data.result) {
        setLoading(false);
        setOpenCharge(false);
      }
    }
  };
  const handleChangeSwitch = () => {
    setSendEmail(!sendEmail);
  };
  const calculateDocExpirationDate = () => {
    let docExpiration;
    if (doc.due_date !== null) {
      if (statusPayment(doc.payment.status, doc.due_date) === "expirado") {
        docExpiration = moment(getCurrentDate())
          .add(1, "days")
          .subtract(1, "seconds")
          .format("YYYY-MM-DD HH:mm:ss");
      } else {
        docExpiration = moment(doc.due_date).format("YYYY-MM-DD HH:mm:ss");
      }
    } else {
      docExpiration = doc.metadata
        ? doc.metadata.formaPago === "Contado"
          ? moment(emissionDate)
              .add(47, "hours")
              .add(59, "minutes")
              .add(59, "seconds")
              .format("YYYY-MM-DD HH:mm:ss")
          : moment(doc.due_date)
              .add(1, "days")
              .subtract(1, "seconds")
              .format("YYYY-MM-DD HH:mm:ss")
        : moment(getCurrentDate())
            .add(1, "days")
            .subtract(1, "seconds")
            .format("YYYY-MM-DD HH:mm:ss");
    }
    return docExpiration;
  };
  const checkAddNewPhoneNumber = () => {
    
    if (formWsp.numberWsp.trim() === "" || formWsp.code.trim() === "") {
      setHasNewPhoneNumberError(true);
      setErrorMsgNewPhoneNumber(
        "Debes especificar un número correcto"
      );
      return false;
    } else {
      setHasNewPhoneNumberError(false);
      setErrorMsgNewPhoneNumber(null);
      return true;
    }
  };
  return (
    <React.Fragment>
      <TableRow>
        <TableCell>
          <Tooltip title="Detalles" arrow disableHoverListener={open}>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </Tooltip>
        </TableCell>
        <TableCell component="th" scope="row">
          <span className={doc.state === "2" ? "voided" : ""}>
            {moment(doc.issue_date).format("DD/MM/YYYY")}
          </span>
        </TableCell>
        <TableCell align="left">
          <div
            style={{
              justifyContent: "flex-start",
              flexDirection: "row",
              display: "flex",
            }}
          >
            <Tooltip arrow title={getDocumentStatusDescription(doc.state)}>
              <div>
                {["1", "3", "2", "5"].includes(doc.state) && (
                  <ValidIcon
                    style={{
                      fontSize: 20,
                      color:
                        doc.state === "2"
                          ? Colors.voided
                          : doc.state === "5"
                          ? Colors.pending
                          : Colors.successful,
                      marginRight: 5,
                    }}
                  />
                )}
                {doc.state === "0" && (
                  <WarningTwoTone
                    style={{
                      fontSize: 20,
                      color: "rgb(255 178 47)",
                      marginRight: 5,
                    }}
                  />
                )}
                {["-1", "-2", "4"].includes(doc.state) && (
                  <ReportTwoTone
                    style={{
                      fontSize: 20,
                      color: Colors.alert,
                      marginRight: 5,
                    }}
                  />
                )}
              </div>
            </Tooltip>
            <span
              className={doc.state === "2" ? "voided" : ""}
            >{`${doc.serie}-${doc.number}`}</span>
          </div>
        </TableCell>
        <TableCell align="left">
          <span className={doc.state === "2" ? "voided" : ""}>
            {getDocumentTypeDesc(doc.document_type)}
          </span>
        </TableCell>
        <TableCell align="left">
          <span className={doc.state === "2" ? "voided" : ""}>
            {doc.receiver.legal_name}
          </span>
        </TableCell>
        <TableCell align="right">
          <span className={doc.state === "2" ? "voided" : ""}>
            {`${getCurrencySymbol(doc.currency)} ${numberWithCommas(
              doc.total_value
            )}`}
          </span>
        </TableCell>
        <TableCell align="left">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "baseline",
            }}
          >
            {!["-1", "-2", "4"].includes(doc.state) && (
              <Tooltip title="Enviar copia a correo electrónico" arrow>
                <IconButton
                  size="small"
                  onClick={() => onResentDocumentEmail(doc)}
                >
                  <MobileScreenShare
                    style={{
                      fontSize: 20,
                      color: Colors.iconOlive,
                    }}
                  />
                </IconButton>
              </Tooltip>
            )}
            <Tooltip title="Imprimir voucher" arrow>
              <IconButton
                size="small"
                onClick={() => printVoucher(doc.file_name, doc.id)}
              >
                <Receipt
                  style={{
                    fontSize: 20,
                    color: Colors.iconOlive,
                  }}
                />
              </IconButton>
            </Tooltip>
            <Tooltip title="Descargar PDF" arrow>
              <IconButton
                size="small"
                onClick={() => downloadPdf(doc.file_name, doc.id)}
              >
                <PictureAsPdf
                  style={{
                    fontSize: 20,
                    color: Colors.iconOlive,
                  }}
                />
              </IconButton>
            </Tooltip>
            {["1", "3"].includes(doc.state) &&
              ["01", "03", "07", "08"].includes(doc.document_type) &&
              companies.find((c: CompanyInfo) => c.ruc === config.workspace)
                .customize.openPay === "YES" && (
                <>
                  {doc.payment !== null ? (
                    <>
                      {doc.payment.status === "pagado" ? (
                        <Tooltip title="Pagado" arrow>
                          <IconButton size="small">
                            <TouchApp
                              style={{
                                fontSize: 20,
                                color: Colors.border,
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                      ) : statusPayment(
                          doc.payment.status,
                          doc.payment !== null &&
                            doc.payment.expiration_date !== null
                            ? doc.payment.expiration_date
                            : calculateDocExpirationDate()
                        ) === "expirado" ? (
                        <Tooltip title="Expirado" arrow>
                          <IconButton
                            size="small"
                            onClick={() => {
                              operationCharge();
                              handleOpenCharge();
                            }}
                          >
                            <TouchApp
                              style={{
                                fontSize: 20,
                                color: Colors.alert,
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                      ) : (
                        statusPayment(
                          doc.payment.status,
                          doc.payment !== null &&
                            doc.payment.expiration_date !== null
                            ? doc.payment.expiration_date
                            : calculateDocExpirationDate()
                        ) === "pendiente" && (
                          <Tooltip title="Pago pendiente" arrow>
                            <IconButton
                              size="small"
                              onClick={() => {
                                handleOpenCharge();
                                operationCharge();
                              }}
                            >
                              <TouchApp
                                style={{
                                  fontSize: 20,
                                  color: Colors.pending,
                                }}
                              />
                            </IconButton>
                          </Tooltip>
                        )
                      )}
                    </>
                  ) : (
                    <Tooltip title="Cobrar" arrow>
                      <IconButton
                        size="small"
                        onClick={() => {
                          if (hasCredentialsOpenPay()) {
                            operationCharge();
                            handleOpenCharge();
                          } else {
                            setShowAlert(true);
                          }
                        }}
                      >
                        <TouchApp
                          style={{
                            fontSize: 20,
                            color: Colors.iconOlive,
                          }}
                        />
                      </IconButton>
                    </Tooltip>
                  )}
                </>
              )}
              {/*Coment WhatsApp*/}
              {
                (emitter.customize["waba"]==="PRIVATE" || emitter.customize["waba"]==="EXIMIO" || emitter.customize["waba"]==="PUBLIC" ) && (
                  <Tooltip title="Enviar WhatsApp" arrow>
                    <IconButton size="small" onClick={() => {
                        setFileNameWaba(doc.file_name);
                        setCopySentWaba(false);
                        setErrorSendWaba("");
                        setLoadingSendWaba(false);
                        formWsp.numberWsp = "";
                        setHasNewPhoneNumberError(false);
                        setErrorMsgNewPhoneNumber("");
                        setOpenWsp(true)
                      }}>
                      <WhatsAppIcon
                        style={{
                          fontSize: 20,
                          color: Colors.iconOlive,
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                )
              }
          </div>
        </TableCell>
      </TableRow>
      {/*   Modal de cobro */}
      <Dialog open={openWsp} onClose={() => setOpenWsp(false)} maxWidth="xs">
        <DialogTitle>
          {
            copySentWaba ? (
              <Typography
                variant="h4"
                style={{
                  color: Colors.textOlive,
                  marginBottom: 20,
                }}
              >
                Copia enviada
              </Typography>
            ):(
              <TextStyle
                color={Colors.GREEN}
                type="h1"
                textAlign="center"
                bold={700}
              >
                Enviar a WhatsApp
              </TextStyle>
            )
          }
        </DialogTitle>
        <DialogContent>
        {
          copySentWaba ? (
            <Slide direction="left" in={copySentWaba}>
              <div>
                <Typography
                  variant="body2"
                  style={{
                    color: Colors.textOlive,
                  }}
                >
                  Se ha enviado una copia del documento{" "}
                  <strong>
                    {doc.serie}-{doc.number}
                  </strong>{" "}
                  al número: {formWsp.numberWsp}
                </Typography>
                <div style={{ textAlign: "center" }}>
                  
                </div>
              </div>
            </Slide>
          ):(
            <>
            {errorSendWaba !== ""? (
              <span style={{ color: "red" }}>
                {errorSendWaba}
              </span>
            ):null}
              <CustomSelect
                name="code"
                placeholder="Escoger código de país"
                value={formWsp.code}
                options={countries}
                onChange={onChangeSelect}
              />
              <InputNumber
                maxLength={9}
                name="numberWsp"
                value={formWsp.numberWsp}
                onChange={onChangeWsp}
                placeholder="Número"
                error={hasNewPhoneNumberError}
                helperText={errorMsgNewPhoneNumber}
              />
          
            </>
          )
        }
        {copySentWaba ? null:(
          <ButtonSecondary
          border={Colors.GREEN}
          color={Colors.GREEN}
          onClick={() => setOpenWsp(false)}
          size="16px"
        >
          Cancelar
        </ButtonSecondary>
        )}
        
          {(emitter.customize["waba"]==="PRIVATE" || emitter.customize["waba"]==="EXIMIO") && (
            <ButtonPrimary
              onClick={() => {
                if(copySentWaba){
                  setOpenWsp(false);
                }
                else{
                  if((emitter.customize["waba"]==="PRIVATE" || emitter.customize["waba"]==="EXIMIO") && checkAddNewPhoneNumber()){
                    sendPdfWaba(doc.file_name, doc.id)
                  }
                }
              }}
              background={Colors.GREEN}
              color={Colors.WHITE}
              size="16px"
              disabled={loadingSendWaba}
            >
              {loadingSendWaba ? (
                <>
                  <CircularProgress
                    variant="indeterminate"
                    size={14}
                    style={{ marginRight: 5 }}
                  />
                  Enviando...
                </>
              ) : copySentWaba ? (
                "Cerrar"
              ) : (
                "Enviar"
              )}
            </ButtonPrimary>
          )}
          {
            emitter.customize["waba"]==="PUBLIC" && (
              <a
                target="_blank"
                href={`https://wa.me/${formWsp.code}${
                  formWsp.numberWsp
                }?text=${BASE_URL}/consultar-resultado/${doc.document_type}/${
                  doc.serie
                }/${doc.number}/${doc.total_value}/${emitter.ruc}/${moment(
                  doc.issue_date
                ).format("YYYY-MM-DD")}/${
                  doc.receiver.number
                }%0ABuen día, enviamos el comprobante de su compra realizada en ${
                  emitter.legal_name
                }%0ADescárguelo aquí
                `}
                style={{ textDecoration: "none" }}
              >
                <ButtonPrimary
                  onClick={() => {
                    setOpenWsp(false)
                  }}
                  background={Colors.GREEN}
                  color={Colors.WHITE}
                  size="16px"
                >
                  Enviar
                </ButtonPrimary>
              </a>
            )
          }
        </DialogContent>
      </Dialog>
      <Dialog open={openCharge} onClose={handleCloseCharge} maxWidth="xs">
        <DialogTitle>
          <Typography variant="caption">Detalle de la cuenta</Typography>
        </DialogTitle>
        <DialogContent>
          <div style={{ textAlign: "center" }}>
            <Grid container alignItems="center">
              <Grid item xs={12} md={12}>
                <RChip
                  icon={<Business style={{ color: "#ffffff" }} />}
                  label={doc.receiver.legal_name}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <Typography gutterBottom variant="h4">
                  {getCurrencySymbol(doc.currency)}{" "}
                  {doc.detraction_detail !== null
                    ? (doc.total_value - doc.detraction_detail.monto).toFixed(2)
                    : doc.total_value}
                </Typography>
              </Grid>
              <List style={{ padding: "0px" }}>
                <ListItem style={{ padding: "0px" }}>
                  <ListItemIcon>
                    <Avatar>
                      <Work />
                    </Avatar>
                  </ListItemIcon>
                  <RTextField
                    fullWidth
                    id="descrip"
                    label="Concepto"
                    variant="outlined"
                    size="small"
                    error={errorConcepto}
                    required
                    value={concepto}
                    disabled={
                      doc.payment && doc.payment.redirect_url ? true : false
                    }
                    onChange={(e) => {
                      setConcepto(e.target.value);
                      if (concepto.trim.length < 5) {
                        setErrorConcepto(false);
                      }
                    }}
                    margin="normal"
                  />
                </ListItem>
                <ListItem style={{ padding: "0px" }}>
                  <ListItemIcon>
                    <Avatar>
                      <Email />
                    </Avatar>
                  </ListItemIcon>
                  <div>
                    <FormControlLabel
                      control={
                        <RSwitch
                          checked={sendEmail}
                          onChange={handleChangeSwitch}
                          name="checkedB"
                          disabled={
                            doc.payment &&
                            statusPayment(
                              doc.payment.status,
                              doc.payment.expiration_date
                            ) === "pendiente"
                          }
                        />
                      }
                      label="Enviar link de pago"
                    />
                    {sendEmail ? (
                      <RTextField
                        fullWidth
                        label="Email"
                        variant="outlined"
                        size="small"
                        value={email}
                        margin="normal"
                        error={errorEmail}
                        helperText={errorMsgEmail}
                        onChange={(e) => {
                          setEmail(e.target.value);
                          if (email !== "") {
                            setErrorEmail(false);
                          }
                        }}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </ListItem>
              </List>

              <hr />
              <Grid item xs={12}>
                <Divider variant="fullWidth" />
                <Typography gutterBottom variant="caption">
                  Fecha límite de pago
                </Typography>
                <br />
                <RChip
                  style={{ backgroundColor: Colors.pending }}
                  icon={<Event style={{ color: "#ffffff" }} />}
                  label={expirationDate}
                />
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={12} md={12}></Grid>
            </Grid>
          </div>
        </DialogContent>
        <DialogActions>
          {doc.payment !== null &&
          statusPayment(doc.payment.status, doc.payment.expiration_date) ===
            "pendiente" ? (
            <RButton
              color="primary"
              autoFocus
              onClick={() => {
                resendCharge();
              }}
              disabled={loading}
            >
              {loading ? (
                <>
                  <CircularProgress
                    variant="indeterminate"
                    size={14}
                    style={{ marginRight: 5 }}
                  />
                  Enviando...
                </>
              ) : (
                "Reenviar link"
              )}
            </RButton>
          ) : (
            // <RButton
            //   color="primary"
            //   autoFocus
            //   onClick={() => {
            //     generateCharge();
            //   }}
            //   disabled={loading}
            // >
            //   {loading ? (
            //     <>
            //       <CircularProgress
            //         variant="indeterminate"
            //         size={14}
            //         style={{ marginRight: 5 }}
            //       />
            //       Enviando...
            //     </>
            //   ) : (
            //     "Generar cargo"
            //   )}
            // </RButton>
            <ButtonPrimary
              onClick={() => {
                generateCharge();
              }}
              disabled={loading}
              background={Colors.GREEN}
              color={Colors.WHITE}
              size="14px"
              width="120px"
            >
              {loading ? (
                <>
                  <CircularProgress
                    variant="indeterminate"
                    size={14}
                    style={{ marginRight: 5 }}
                  />
                  Enviando...
                </>
              ) : (
                "Generar cargo"
              )}
            </ButtonPrimary>
          )}
          <ButtonSecondary
            onClick={handleCloseCharge}
            disabled={loading}
            border={Colors.GREEN}
            color={Colors.GREEN}
            size="14px"
            width="100px"
          >
            Cancelar
          </ButtonSecondary>
        </DialogActions>
      </Dialog>

      <TableRow style={{ backgroundColor: "#f7f7f7" }}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              {["-1", "-2", "4"].includes(doc.state) && (
                <NoteBox
                  type={NoteType.Error}
                  title={getDocumentStatusDescription(doc.state)}
                  body={doc.error_desc}
                />
              )}
              {doc.state === "0" && (
                <NoteBox
                  type={NoteType.Warning}
                  title="Pendiente"
                  body={getDocumentStatusDescription(doc.state)}
                />
              )}
              {doc.state === "2" && (
                <NoteBox
                  type={NoteType.Info}
                  title="Anulado"
                  body={getDocumentStatusDescription(doc.state)}
                  details={
                    doc.metadata
                      ? [
                          `Comprobante de baja: ${doc.metadata.void_number}`,
                          `Emitido el: ${moment(doc.metadata.void_date).format(
                            "DD/MM/YYYY"
                          )}`,
                          `Ticket: ${doc.metadata.void_ticket}`,
                          `Motivo de la anulación: ${doc.metadata.void_reason}`,
                        ]
                      : []
                  }
                />
              )}
              <Grid container spacing={5} style={{ marginBottom: 10 }}>
                <Grid item xs={6} sm={4}>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    style={{ fontSize: 13 }}
                  >
                    {getDocumentTypeDesc(doc.document_type)}
                  </Typography>
                  <Typography variant="h5" component="h2">
                    {`${doc.serie}-${doc.number}`}
                  </Typography>
                  {/* Si es ok, poner en green: #2b824e */}
                  <Typography color="textSecondary" style={{ fontSize: 13 }}>
                    {getDocumentStatusDescription(doc.state)}
                  </Typography>

                  <Typography
                    color="textSecondary"
                    gutterBottom
                    style={{ fontSize: 13, marginTop: 10, marginBottom: 0 }}
                  >
                    Emitido
                  </Typography>
                  <Typography variant="body2" component="p">
                    {moment(doc.issue_date).format("DD/MM/YYYY hh:mm A")}
                  </Typography>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    style={{ fontSize: 13, marginTop: 5, marginBottom: 0 }}
                  >
                    Enviado
                  </Typography>
                  <Typography variant="body2" component="p">
                    {moment(doc.created_at).format("DD/MM/YYYY hh:mm A")}
                  </Typography>
                  <div>
                    {/* Copias de whatsapp */}
                    {doc.messages_recipients.length > 0 && (
                      <>
                        <Typography
                          color="textSecondary"
                          gutterBottom
                          style={{ marginTop: 20, fontSize: 13 }}
                        >
                          Copia a whatsapp
                        </Typography>
                        {/* Muestra el numero de whatsapp al que se envio una copia */}
                        {[
                          ...new Map(
                            doc.messages_recipients
                              .sort((a, b) => {
                                return (
                                  new Date(a.created_at).getTime() -
                                  new Date(b.created_at).getTime()
                                );
                              })
                              .map((item, index) => [item["to_number"], item])
                          ).values(),
                        ]
                          .sort((a, b) => {
                            return (
                              new Date(b.created_at).getTime() -
                              new Date(a.created_at).getTime()
                            );
                          })
                          .map((recipient, index) => (
                            <div
                              key={index}
                              style={{
                                alignItems: "center",
                                flexDirection: "row",
                                display: "flex",
                              }}
                            >
                              <WhatsAppIcon
                                style={{
                                  fontSize: 16,
                                  color: Colors.GREEN,
                                  marginRight: 5,
                                }}
                              />
                              {recipient.to_number}
                              {recipient.state && JSON.parse(recipient.state)['id'] === 7 && (
                                <Tooltip
                                  title={
                                    `Enviado el ${moment(
                                          recipient.sent_at?recipient.sent_at:recipient.created_at
                                        ).format("DD/MM/YYYY hh:mm A")}`
                                  }
                                  arrow
                                >
                                  <Done
                                    style={{
                                      fontSize: 16,
                                      color: Colors.pending,
                                      marginLeft: 5,
                                    }}
                                  />
                                </Tooltip>
                              )}
                              {recipient.state && JSON.parse(recipient.state)['id'] === 5 && !recipient.seen_at && (
                                <Tooltip
                                  title={
                                    `Recibido el ${moment(
                                          recipient.done_at
                                        ).format("DD/MM/YYYY hh:mm A")}`
                                  }
                                  arrow
                                >
                                  <Done
                                    style={{
                                      fontSize: 16,
                                      color: Colors.successful,
                                      marginLeft: 5,
                                    }}
                                  />
                                </Tooltip>
                              )}
                              {recipient.sent_at && recipient.done_at && recipient.seen_at && (
                                <Tooltip
                                  title={
                                    `Leído el ${moment(
                                          recipient.seen_at
                                        ).format("DD/MM/YYYY hh:mm A")}`
                                  }
                                  arrow
                                >
                                  <DoneAll
                                    style={{
                                      fontSize: 16,
                                      color: Colors.successful,
                                      marginLeft: 5,
                                    }}
                                  />
                                </Tooltip>
                              )}
                              { recipient.error && JSON.parse(recipient.error)['id'] !== 0 && !recipient.seen_at && (
                                <Tooltip
                                  title={
                                    "Erroneo"
                                  }
                                  arrow
                                >
                                  <Done
                                    style={{
                                      fontSize: 16,
                                      color: Colors.alert,
                                      marginLeft: 5,
                                    }}
                                  />
                                </Tooltip>
                              )}
                            </div>
                          ))}

                        {/* Muestra el historial, solo si hay mas de un mensaje de whatsapp/estado */}
                        {doc.messages_recipients.length > 1 && (
                          <Button
                            color="primary"
                            size="small"
                            disableElevation
                            style={{ marginTop: 5 }}
                            onClick={() =>
                              onGetWabaHistory(doc.messages_recipients)
                            }
                          >
                            Ver Historial
                          </Button>
                        )} 
                      </>
                    )}
                  </div>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    style={{ fontSize: 13 }}
                  >
                    Receptor
                  </Typography>
                  <div>
                    <div>
                      <strong>{doc.receiver.legal_name}</strong>
                    </div>
                    {/* Se muestra informacion del receptor si es distinto de "No especificado" */}
                    {doc.receiver.number !== "11111111" && (
                      <>
                        <div style={{ marginBottom: 10 }}>
                          {getIDDocumentType(doc.receiver.number_type)}:{" "}
                          {doc.receiver.number}
                        </div>
                        <Typography style={{ fontSize: 14, marginBottom: 5 }}>
                          {getReceiverFullAddress(doc.receiver_address)}
                        </Typography>
                      </>
                    )}
                    {doc.receiver.phone_number && (
                      <div
                        style={{
                          alignItems: "center",
                          flexDirection: "row",
                          display: "flex",
                          marginBottom: 5,
                        }}
                      >
                        <PhoneIcon
                          style={{
                            fontSize: 16,
                            marginRight: 5,
                          }}
                        />{" "}
                        {doc.receiver.phone_number}
                      </div>
                    )}
                    {/* Copias de emails */}
                    {doc.email_recipients.length > 0 && (
                      <>
                        <Typography
                          color="textSecondary"
                          gutterBottom
                          style={{ marginTop: 20, fontSize: 13 }}
                        >
                          Copia a email
                        </Typography>
                        {/* Muestra la lista de emails enviados, mostrando unicos emails en ultimo estado */}
                        {[
                          ...new Map(
                            doc.email_recipients
                              .sort((a, b) => {
                                return (
                                  new Date(a.created_at).getTime() -
                                  new Date(b.created_at).getTime()
                                );
                              })
                              .map((item) => [item["email"], item])
                          ).values(),
                        ]
                          .sort((a, b) => {
                            return (
                              new Date(b.created_at).getTime() -
                              new Date(a.created_at).getTime()
                            );
                          })
                          .map((recipient, index) => (
                            <div
                              key={index}
                              style={{
                                alignItems: "center",
                                flexDirection: "row",
                                display: "flex",
                              }}
                            >
                              {recipient.state === 1 && (
                                <Tooltip
                                  title={
                                    recipient.desc
                                      ? recipient.desc
                                      : `Enviado el ${moment(
                                          recipient.created_at
                                        ).format("DD/MM/YYYY hh:mm A")}`
                                  }
                                  arrow
                                >
                                  <SentIcon
                                    style={{
                                      fontSize: 16,
                                      color: Colors.pending,
                                      marginRight: 5,
                                    }}
                                  />
                                </Tooltip>
                              )}
                              {recipient.state === 2 && (
                                <Tooltip
                                  title={
                                    recipient.desc
                                      ? recipient.desc
                                      : `Recibido el ${moment(
                                          recipient.created_at
                                        ).format("DD/MM/YYYY hh:mm A")}`
                                  }
                                  arrow
                                >
                                  <RecivedIcon
                                    style={{
                                      fontSize: 16,
                                      color: Colors.successful,
                                      marginRight: 5,
                                    }}
                                  />
                                </Tooltip>
                              )}
                              {recipient.state === 3 && (
                                <Tooltip
                                  title={
                                    recipient.desc
                                      ? recipient.desc
                                      : `Leído el ${moment(
                                          recipient.created_at
                                        ).format("DD/MM/YYYY hh:mm A")}`
                                  }
                                  arrow
                                >
                                  <ReadIcon
                                    style={{
                                      fontSize: 16,
                                      color: Colors.successful,
                                      marginRight: 5,
                                    }}
                                  />
                                </Tooltip>
                              )}
                              {[-1, -2].includes(recipient.state) && (
                                <Tooltip
                                  title={
                                    recipient.desc ? recipient.desc : "Erroneo"
                                  }
                                  arrow
                                >
                                  <UnsentIcon
                                    style={{
                                      fontSize: 16,
                                      color: Colors.alert,
                                      marginRight: 5,
                                    }}
                                  />
                                </Tooltip>
                              )}
                              {recipient.email}
                            </div>
                          ))}
                        {/* Muestra el historial, solo si hay mas de un email/estado */}
                        {doc.email_recipients.length > 1 && (
                          <Button
                            color="primary"
                            size="small"
                            disableElevation
                            style={{ marginTop: 5 }}
                            onClick={() =>
                              onGetEmailHistory(doc.email_recipients)
                            }
                          >
                            Ver Historial
                          </Button>
                        )}
                      </>
                    )}
                  </div>
                  {doc.note && (
                    <div style={{ marginTop: 20 }}>
                      <Typography
                        color="textSecondary"
                        gutterBottom
                        style={{ fontSize: 13, marginTop: 5, marginBottom: 0 }}
                      >
                        Documento afectado
                      </Typography>
                      <strong>{doc.note.ref_serie_number}</strong>
                      <Typography
                        color="textSecondary"
                        gutterBottom
                        style={{ fontSize: 13, marginTop: 5, marginBottom: 0 }}
                      >
                        {`Tipo de Nota de ${
                          doc.document_type === "07" ? "Crédito" : "Débito"
                        }`}
                      </Typography>
                      <Typography variant="body2" component="p">
                        {doc.document_type === "07"
                          ? getCreditNoteReason(doc.note.response_code)
                          : getDebitNoteReason(doc.note.response_code)}
                      </Typography>
                      <Typography
                        color="textSecondary"
                        gutterBottom
                        style={{ fontSize: 13, marginTop: 5, marginBottom: 0 }}
                      >
                        Motivo o sustento
                      </Typography>
                      <Typography variant="body2" component="p">
                        {doc.note.description}
                      </Typography>
                    </div>
                  )}
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    style={{ fontSize: 13 }}
                  >
                    Montos
                  </Typography>
                  <table className="table table-clear table-montos">
                    <tbody>
                      {(doc.base_unaffected > 0 || doc.base_exemption > 0) && (
                        <tr>
                          <td className="left">
                            <strong>Total Gravado</strong>
                          </td>
                          <td className="right">{`${getCurrencySymbol(
                            doc.currency
                          )} ${numberWithCommas(doc.base_igv)}`}</td>
                        </tr>
                      )}
                      {doc.base_unaffected > 0 && (
                        <tr>
                          <td className="left">
                            <strong>Total Inafecto</strong>
                          </td>
                          <td className="right">{`${getCurrencySymbol(
                            doc.currency
                          )} ${numberWithCommas(doc.base_unaffected)}`}</td>
                        </tr>
                      )}
                      {doc.base_exemption > 0 && (
                        <tr>
                          <td className="left">
                            <strong>Total Exonerado</strong>
                          </td>
                          <td className="right">{`${getCurrencySymbol(
                            doc.currency
                          )} ${numberWithCommas(doc.base_exemption)}`}</td>
                        </tr>
                      )}
                      {doc.subtotal > 0 && (
                        <tr>
                          <td className="left">
                            <strong>Subtotal</strong>
                          </td>
                          <td className="right">{`${getCurrencySymbol(
                            doc.currency
                          )} ${numberWithCommas(doc.subtotal)}`}</td>
                        </tr>
                      )}
                      <tr>
                        <td className="left">
                          <strong>IGV</strong>
                        </td>
                        <td className="right">{`${getCurrencySymbol(
                          doc.currency
                        )} ${numberWithCommas(doc.total_igv)}`}</td>
                      </tr>
                      {doc.total_isc > 0 && (
                        <tr>
                          <td className="left">
                            <strong>ISC</strong>
                          </td>
                          <td className="right">{`${getCurrencySymbol(
                            doc.currency
                          )} ${numberWithCommas(doc.total_isc)}`}</td>
                        </tr>
                      )}
                      {/* Add other_taxes: [xxx] */}
                      {doc.other_charges > 0 && (
                        <tr>
                          <td className="left">
                            <strong>Otros cargos</strong>
                          </td>
                          <td className="right">{`${getCurrencySymbol(
                            doc.currency
                          )} ${numberWithCommas(doc.other_charges)}`}</td>
                        </tr>
                      )}
                      {doc.total_discount > 0 && (
                        <tr>
                          <td className="left">
                            <strong>Descuentos</strong>
                          </td>
                          <td className="right">{`- ${getCurrencySymbol(
                            doc.currency
                          )} ${numberWithCommas(doc.total_discount)}`}</td>
                        </tr>
                      )}
                      {doc.prepaid_amount > 0 && (
                        <tr>
                          <td className="left">
                            <strong>Anticipo</strong>
                          </td>
                          <td className="right">{`${getCurrencySymbol(
                            doc.currency
                          )} ${numberWithCommas(doc.prepaid_amount)}`}</td>
                        </tr>
                      )}
                      <tr>
                        <td className="left">
                          <strong>Total</strong>
                        </td>
                        <td className="right">
                          <strong>{`${getCurrencySymbol(
                            doc.currency
                          )} ${numberWithCommas(doc.total_value)}`}</strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Grid>
              </Grid>
              <Typography
                color="textSecondary"
                gutterBottom
                style={{ fontSize: 13 }}
              >
                Detalles
              </Typography>
              <Table
                size="small"
                aria-label="purchases"
                style={{ marginBottom: 20 }}
                className="table-details"
              >
                <TableHead>
                  <TableRow>
                    <TableCell style={{ fontWeight: "bold" }}>
                      Cantidad
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>Código</TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>
                      Descripción
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold" }} align="right">
                      Precio unitario
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold" }} align="right">
                      Precio total
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {doc.products.map((product) => (
                    <TableRow key={product.line}>
                      <TableCell
                        component="th"
                        scope="row"
                        style={{ minWidth: 70 }}
                      >
                        {product.quantity} {getUnidadMedida(product.measure)}
                      </TableCell>
                      <TableCell>{product.code}</TableCell>
                      <TableCell>{product.description}</TableCell>
                      <TableCell align="right" style={{ minWidth: 105 }}>
                        {`${getCurrencySymbol(doc.currency)} ${numberWithCommas(
                          Number(product.unit_price)
                        )}`}
                      </TableCell>
                      <TableCell align="right" style={{ minWidth: 80 }}>
                        {`${getCurrencySymbol(doc.currency)} ${numberWithCommas(
                          Number(product.unit_price) > 0 ? product.price : 0
                        )}`}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <div
                style={{
                  marginTop: 10,
                  marginBottom: 20,
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    marginTop: 10,
                    marginBottom: 20,
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                >
                  {canEmitNote(doc) && (
                    <Grid container direction="column" alignItems="center">
                      <Grid item xs={12}>
                        <ButtonGroup
                          variant="outlined"
                          color="primary"
                          aria-label="button"
                        >
                          <Button
                            variant="outlined"
                            color="primary"
                            size="small"
                            disabled={true}
                            disableElevation
                          >
                            Emitir Nota
                          </Button>
                          <Button
                            variant="contained"
                            color="default"
                            size="small"
                            disableElevation
                            onClick={() => onEmitCreditNote(doc)}
                          >
                            Crédito
                          </Button>
                          <Button
                            variant="contained"
                            color="default"
                            size="small"
                            disableElevation
                            onClick={() => onEmitDebitNote(doc)}
                          >
                            Débito
                          </Button>
                        </ButtonGroup>
                      </Grid>
                    </Grid>
                  )}

                  {canVoidDocument(doc) && (
                    <Tooltip title="Emitir comunicación de baja" arrow>
                      <Button
                        variant="outlined"
                        color="secondary"
                        size="small"
                        disableElevation
                        style={{ marginRight: 5 }}
                        onClick={() =>
                          onSendVoided({
                            doc_type: doc.document_type,
                            serie: doc.serie,
                            number: doc.number,
                            issue_date: doc.issue_date,
                          })
                        }
                      >
                        Anular
                      </Button>
                    </Tooltip>
                  )}

                  {canEmitGuia(doc) && (
                    <Tooltip title="Emitir Guía de remisión" arrow>
                      <Button
                        variant="outlined"
                        color="primary"
                        size="small"
                        disableElevation
                        style={{ marginRight: 5 }}
                        onClick={() =>
                          {
                            history.push("/nueva-guia", {...doc, typeEmission: "ventas"})
                          }
                        }
                      >
                        Guia
                      </Button>
                    </Tooltip>
                  )}
                </div>
                <div
                  style={{
                    marginTop: 10,
                    marginBottom: 20,
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  {doc.state === "2" && (
                    <Tooltip title="Descargar archivo de baja Xml" arrow>
                      <Button
                        variant="contained"
                        color="default"
                        size="small"
                        disableElevation
                        style={{ marginRight: 5 }}
                        startIcon={
                          <DownloadIcon
                            style={{
                              fontSize: 20,
                              color: Colors.iconOlive,
                            }}
                          />
                        }
                        onClick={() =>
                          downloadXmlVoid(
                            doc.metadata ? doc.metadata.void_number : "",
                            doc.id
                          )
                        }
                      >
                        {doc.metadata
                          ? doc.metadata.void_number.substr(
                              12,
                              doc.metadata.void_number.length
                            )
                          : "BAJA"}
                      </Button>
                    </Tooltip>
                  )}
                  <Tooltip title="Descargar archivo Xml" arrow>
                    <Button
                      variant="contained"
                      color="default"
                      size="small"
                      disableElevation
                      style={{ marginRight: 5 }}
                      startIcon={
                        <DownloadIcon
                          style={{
                            fontSize: 20,
                            color: Colors.iconOlive,
                          }}
                        />
                      }
                      onClick={() => downloadXml(doc.file_name, doc.id)}
                    >
                      XML
                    </Button>
                  </Tooltip>
                  {["1", "2", "3", "-2"].includes(doc.state) && (
                    <Tooltip title="Descargar archivo CDR" arrow>
                      <Button
                        variant="contained"
                        color="default"
                        size="small"
                        disableElevation
                        startIcon={
                          <DownloadIcon
                            style={{
                              fontSize: 20,
                              color: Colors.iconOlive,
                            }}
                          />
                        }
                        onClick={() => downloadCDR(doc.file_name, doc.id)}
                      >
                        CDR
                      </Button>
                    </Tooltip>
                  )}
                </div>
              </div>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      <Backdrop
        open={showAlert}
        onClick={() => {
          setShowAlert(false);
        }}
        style={{ color: "#fff" }}
        className={classes.backdrop}
      >
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={showAlert}
          onClose={() => {
            setShowAlert(false);
          }}
          autoHideDuration={6000}
          style={{ marginTop: "50px" }}
        >
          <Alert
            onClose={() => {
              setShowAlert(false);
            }}
            severity="warning"
            icon={<Feedback />}
          >
            Las credenciales de Openpay aún no han sido configuradas para este
            tipo de moneda, por favor agrega las credenciales en la
            configuración de su empresa.
          </Alert>
        </Snackbar>
      </Backdrop>
    </React.Fragment>
  );
};

export const EmittedDocList: React.FC<{}> = () => {
  const emittedDocs = useSelector((state: any) => state.emittedDocs);
  const pageTotal = useSelector(
    (state: any) => state.config.emittedPageTotal || 0
  );
  const rowsPerPage = useSelector(
    (state: any) => state.config.emittedRowsPerPage || 10
  );
  const page = useSelector(
    (state: any) => state.config.emittedPageCurrent || 0
  );

  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const [refDocumentToVoid, setRefDocumentToVoid] =
    React.useState<ToVoidDocument | null>(null);
  const [refDocumentToUpdate, setRefDocumentToUpdate] =
    React.useState<EmittedDoc | null>(null); // Documento de referencia para actualizar en una nota de Credito
  const [refDocumentToUpdateDebit, setRefDocumentToUpdateDebit] =
    React.useState<EmittedDoc | null>(null); // Documento de referencia para actualizar en una nota de Debito
  const [refDocumentToResent, setRefDocumentToResent] =
    React.useState<EmittedDoc | null>(null); // Documento de referencia para reenviar por email
  const [emailHistoryList, setEmailHistoryList] = React.useState<EmailState[]>(
    []
  ); // Lista de historial de emails enviados
  const [wabaHistoryList, setWabaHistoryList] = React.useState<Messages[]>(
    []
  ); // Lista de historial de whatsapp enviados

  const config = useSelector((state: any) => state.config);
  const companies = useSelector((state: any) => state.companiesInfo);
  const emitter: CompanyInfo = companies.find(
    (c: CompanyInfo) => c.ruc === config.workspace
  );

  const handleChangePage = async (event: unknown, newPage: number) => {
    dispatch(setConfigParam("emittedPageCurrent", newPage));
    setIsLoading(true);
    // await fetch(["emission"]);
    await emissionService.getEmissionDocuments();
    setIsLoading(false);
  };
  const handleChangeRowsPerPage = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(setConfigParam("emittedRowsPerPage", +event.target.value));
    dispatch(setConfigParam("emittedPageCurrent", 0));
    // fetch(["emission"]);
    await emissionService.getEmissionDocuments();
  };

  const emitVoided = (refDocument: ToVoidDocument) => {
    setRefDocumentToVoid(refDocument);
  };

  const emitCreditNote = (refDocument: EmittedDoc) => {
    setRefDocumentToUpdate(refDocument);
  };

  const emitDebitNote = (refDocument: EmittedDoc) => {
    setRefDocumentToUpdateDebit(refDocument);
  };

  const resentDocumentByEmail = (document: EmittedDoc) => {
    // console.log("fly", document);
    setRefDocumentToResent(document);
  };

  const showHistory = (emailHistoryList: EmailState[]) => {
    setEmailHistoryList(emailHistoryList);
  };

  const showWabaHistory = (wabaHistoryList: Messages[]) => {
    setWabaHistoryList(wabaHistoryList);
  }

  return (
    <div>
      {refDocumentToVoid && (
        <VoidDocument
          emitter={emitter}
          toVoid={refDocumentToVoid}
          open={refDocumentToVoid !== null}
          handleClose={() => setRefDocumentToVoid(null)}
        />
      )}
      {refDocumentToUpdate && (
        <NewCreditNoteModal
          refDocument={refDocumentToUpdate}
          isOpen={refDocumentToUpdate !== null}
          close={() => setRefDocumentToUpdate(null)}
        />
      )}
      {refDocumentToUpdateDebit && (
        <NewDebitNoteModal
          refDocument={refDocumentToUpdateDebit}
          isOpen={refDocumentToUpdateDebit !== null}
          close={() => setRefDocumentToUpdateDebit(null)}
        />
      )}
      {refDocumentToResent && (
        <ResendDocumentEmail
          emitter={emitter}
          document={refDocumentToResent}
          open={refDocumentToResent !== null}
          handleClose={() => setRefDocumentToResent(null)}
        />
      )}
      {emailHistoryList.length > 0 && (
        <EmailHistory
          emailList={emailHistoryList}
          open={emailHistoryList.length > 0}
          handleClose={() => setEmailHistoryList([])}
        />
      )}
      {wabaHistoryList.length > 0 && (
        <WabaHistory
          wabaList={wabaHistoryList}
          open={wabaHistoryList.length > 0}
          handleClose={() => setWabaHistoryList([])}
        />
      )}
      {emittedDocs ? (
        <Paper>
          <TableContainer style={{ minHeight: 450 }}>
            <Table aria-label="collapsible table" size="small">
              <TableHead>
                <TableRow>
                  <TableCell />
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth, fontWeight: "bold" }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {emittedDocs
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((doc: EmittedDoc) => {
                    return (
                      <EmittedDocItem
                        key={doc.id}
                        doc={doc}
                        onSendVoided={emitVoided}
                        onEmitCreditNote={emitCreditNote}
                        onEmitDebitNote={emitDebitNote}
                        onResentDocumentEmail={resentDocumentByEmail}
                        onGetEmailHistory={showHistory}
                        onGetWabaHistory ={showWabaHistory}
                      />
                    );
                  })}
              </TableBody>
            </Table>
            {!config.workspace ? (
              <div className="noResultContainer">
                <img
                  src={emptyDocsIllustration}
                  height={150}
                  style={{ padding: 10 }}
                  alt="No existen documentos"
                />
                <Typography variant="h5" style={{ color: Colors.textOlive }}>
                  Aún no ha emitido documentos
                </Typography>
                <Typography className="noResultLabel">
                  Una vez que se registre y validade la empresa podrás acceder a
                  los documentos emitidos
                </Typography>
              </div>
            ) : (
              emittedDocs.length === 0 && (
                <div className="noResultContainer">
                  <img
                    src={noResultsIllustration}
                    height={180}
                    style={{ padding: 10 }}
                    alt="No existen documentos"
                  />
                  <Typography variant="h5" style={{ color: Colors.textOlive }}>
                    No se encontraron resultados
                  </Typography>
                  <Typography className="noResultLabel">
                    Prueba con otro criterio de búsqueda
                  </Typography>
                </div>
              )
            )}
          </TableContainer>
          {emittedDocs.length >= 10 && (
            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              labelRowsPerPage="Filas por página"
              labelDisplayedRows={({ from, to, count }) =>
                `${from}-${to} de ${count !== -1 ? count : `mas de ${to}`}`
              }
              count={pageTotal}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
          {isLoading && <BorderLinearProgress />}
        </Paper>
      ) : (
        <Loading />
      )}
    </div>
  );
};

const BorderLinearProgress = withStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 3,
      borderRadius: 0,
    },
    colorPrimary: {
      backgroundColor:
        theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
    },
    bar: {
      borderRadius: 0,
      backgroundColor: "rgb(28, 108, 54, 0.5)",
    },
  })
)(LinearProgress);
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 999,
      color: "#fff",
    },
  })
);
